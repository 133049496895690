import React from 'react'
import Principle from 'components/principle'

export default () => (
  <Principle
    number="6"
    summary="Ensure that between them the directors have the necessary up-to-date experience, skills and capabilities."
  >
    <p>
    It is evident from the Directors’ profiles on page 19 of the Annual Report and Accounts for the year ending 31 December 2022 and on the Directors &amp; Management page of this website that there is a good balance of experience and skills that each Director brings to the Board.
    </p>
    <p>
    As detailed above, the Nomination and Remuneration Committee periodically reviews the size, structure and composition of the Board and, in doing so, considers the balance of skills, knowledge, experience and diversity on the Board. Any recommendations from such review are reported to the Board and, should they identify a need for training and development or indeed a change in composition of the Board, they would be actioned appropriately.
    </p>
    <p>
    The Company Secretary supports the Chairman in addressing the training and development needs of Directors to ensure they are kept up-to-date with changes to law, regulations and corporate governance best practice. Where necessary expert professional assistance is sought to keep Directors appraised of developments of a detailed technical or regulatory nature. Notably, an annual update is provided to the Directors by the Company’s Nominated Advisor on requirements arising from AIM Regulation, the Disclosure and Transparency Rules and the Market Abuse Regime and any changes that might have occurred.
    </p>
  </Principle>
)