import React from "react"
import Principle from "components/principle"

export default () => (
  <Principle
    number="5"
    summary="Maintain the board as a well- functioning, balanced team led by the chair."
  >
    <p>
      As detailed on the Directors &mp; Management page of this website and on
      page 19 of the 31 December 2022 Report and Accounts, the Board comprises
      three Executive Directors and three Non-executive Directors. All Directors
      have agreed in the terms of their engagement to commit such time as is
      necessary to discharge their responsibilities to the Company effectively;
      to attend all scheduled Board, Committee, Strategy, Non-executive
      Directors (where applicable) and Shareholder meetings; and to be available
      at all times to discharge their duties effectively.
    </p>
    <p>
      The Board considers the majority of its Non-executive Directors
      independent in character and judgement and reviews, on an on-going basis,
      whether there are any relationships or circumstances which are likely to
      or could affect the independence they bring to matters considered by the
      Board.
    </p>
    <p>
      Each Director’s attendance at Board and Committee meetings is disclosed on
      page 23 of the Annual Report and Accounts for the year ending 31 December
      2022.
    </p>
  </Principle>
)
