import React from "react"
import Principle from "components/principle"

export default () => (
  <Principle
    number="3"
    summary="Take into account wider stakeholder and social responsibilities and their implications for long-term success."
  >
    <p>
      The Board is committed to ensuring the Company’s business remains
      sustainable, not only from the shareholders’ perspective, but also for the
      environment, customers, suppliers, employees, and others affected by the
      Company’s activities. The Company has in place a payment policy which is
      observed and annually reviewed.
    </p>
    <p>
      However, whilst through its subsidiaries the Company owns a portfolio of
      underwriting capacity interests at Lloyd’s, it has no direct
      responsibility for the management of those businesses. Each managing agent
      has responsibility for the management of those businesses, their staff and
      employment policies and the environmental impact. Therefore, the Board
      does not consider it appropriate to monitor or report any performance
      indicators in relation to corporate, social or environmental matters.
    </p>
    <p>
      However, the Company remains focused on participations in quality
      syndicates whose key characteristics are conservative reserving and a
      focus on profit rather than growth. The Board considers these are
      important factors in ensuring long-term success and sustainability.
    </p>
    <p>
      In addition, for a managing agent to operate at Lloyd’s there are
      ‘statements of business conduct’ (‘Lloyd’s Minimum Standards’) with which
      the managing agent must comply. Lloyd’s Minimum Standards encompass
      matters such as claims management, exposure management, governance,
      investment management, reserving and conduct (including the requirement to
      treat customers fairly). The managing agents and senior managers within
      them are also subject to regulation by the Financial Conduct Authority
      (‘FCA’) and the Prudential Regulatory Authority (‘PRA’) and must comply
      with their stringent and regular reporting requirements.
    </p>
    <p>
      On 23 March 2023 the Board approved an Environmental, Social and
      Governance (ESG) Policy Statement. Helios offers investors exposure to a
      diversified portfolio of syndicates at Lloyd’s of London. As a consequence
      Helios is inexorably aligned to the approach Lloyd’s takes with regard to
      the society as a whole in addition to those adopted by the various
      Managing Agencies.
    </p>
    <p>
      As we construct our portfolio each year, considerable emphasis is given to
      understanding individual syndicate actions with regard to ESG. This
      includes an understanding of the risks contemplated as well as the ESG
      initiatives adopted within the respective businesses and their management
      teams.
    </p>
    <p>
      We support the ESG strategy of Lloyd’s, who have outlined their ambition
      to integrate sustainability into all of Lloyd’s business activities.
      Lloyd’s have stated that embedding ESG across the market and Corporation
      is a top priority and is interwoven with their purpose of creating a
      braver world.
    </p>
    <p>
      Helios fully supports Lloyd’s approach and oversight of the market. More
      information can be found at{" "}
      <a href="https://www.lloyds.com/about-lloyds/responsible-business/esgreport2021">
        https://www.lloyds.com/about-lloyds/responsible-business/esgreport2021
      </a>
    </p>
    <p>
      The Chief Executive Officer and Finance Director are in regular contact
      with the managing agents to review performance of the portfolio and
      discuss the market, competition, emerging issues and opportunities.
      Periodically, managing agents are invited to attend Board meetings to
      provide market updates. Information provided by the managing agents is
      considered when making decisions about improvements to the Company’s
      products or business, or about developing new products or exploring new
      opportunities.
    </p>
    <p>
      Interests of the Company’s employees are also key. Engaged, enabled and
      empowered employees who contribute to the best of their potential are
      fundamental to the long-term success of the business. We have six
      employees, including the Chief Executive Officer, the Finance Director and
      the Non-executive Deputy Chairman, and we actively seek to understand
      their values and what motivates them and to take this into account in the
      way we operate. We have a flexible remote working model which has proved
      effective and worked well for our employees, whilst enabling the Company
      to adapt over the past two years. In all instances, two-way communication
      is actively sought and encouraged. Oversight of performance is maintained
      through an annual performance and development review process conducted by
      the Nomination and Remuneration Committee and we seek to offer appropriate
      levels of remuneration and incentives, drawing on comparator benchmark
      surveys as appropriate. The Company’s operations are reliant on key staff.
      The Directors believe that its policies, remuneration and benefit packages
      are appropriate to recruit and retain such staff.
    </p>
    <p>
      Helios is committed to diversity and maintaining an inclusive workplace
      culture where everyone of any background is able to contribute in full to
      the success of our business. Helios believes that a commitment to
      protecting diversity is not only morally imperative but an excellent
      business strategy. While Helios’s workforce is very small, we actively
      engage with our outsource partners, ensuring our ESG principles are
      maintained. In addition Helios is a signatory to the UN Principles For
      Responsible Investment (<a href="https://www.unpri.org/">www.unpri.org</a>
      ) and we strive to adopt the 6 key principles for responsible investment
    </p>
  </Principle>
)
