import React from 'react'
import Principle from 'components/principle'

export default () => (
  <Principle
    number="1"
    summary="Establish a strategy and business model which promotes long-term value for shareholders."
  >
    <p>
    The Company’s business model and strategy, as detailed on the Company Overview page of this website, is to build a portfolio of underwriting capacity at Lloyd’s by acquiring Limited Liability Vehicles owned by private individuals. This portfolio offers investors income which is uncorrelated to equity and debt market cycles. Our strategy is to continue to build a ‘blue chip’ portfolio of underwriting capacity and during this year the Helios retained capacity fund has grown from £172m to £238m. 
    </p>
    <p>
    The majority of the fund is comprised of freehold capacity on well-established syndicates at Lloyd’s. When these syndicates wish to grow their businesses, the existing owners of the capacity have pre-emptive rights to receive additional capacity pro rata to the scale of increase in the underlying business. The additional capacity is free and the value of this additional capacity increases our asset valuation but additional capital is required to meet funds at Lloyd’s. This is a major benefit in holding freehold capacity.
    </p>
    <p>
    This strategy, which the Company will continue to follow, has proved successful to date in delivering shareholders value in the medium to long term and the underwriting performance of the Helios portfolios has consistently outperformed the Lloyd’s market average.
    </p>
  </Principle>
)