import React from "react"
import Principle from "components/principle"

export default () => (
  <Principle
    number="2"
    summary="Seek to understand and meet shareholder needs and expectations."
  >
    <p>
      The Board is committed to communicating effectively with the Company’s
      shareholders and other stakeholders, and to understanding their needs and
      expectations. To achieve this, the Board encourages two-way communication
      with investors and stakeholders and responds appropriately to ensure all
      questions or issues received from them are addressed in a timely manner.
    </p>
    <p>
      The Chief Executive Officer, is the principal point of contact and his
      details are disclosed on the Contacts page of this website. His contact
      details are also disclosed on all announcements made by the Company,
      together with those of Arthur Manners the Finance Director and Nigel
      Hanbury the Non-executive Deputy Chairman, which can be found on the
      Announcements page of this website. In addition, contact details for our
      Company Secretary, Martha Bruce, are given in all communications to
      shareholders about General Meetings and on the Advisers page of this
      website.
    </p>
    <p>
      The Chief Executive Officer, Finance Director and Chairman also have
      regular, direct contact with large shareholders and make sure that their
      opinions are communicated to the Board as needed. There have been regular
      dialogues with shareholders during the year including holding briefings
      with analysts and other investors. The Company uses the Annual General
      Meeting as an opportunity to communicate with its shareholders. In so far
      as is practicably possible, all Directors are expected to attend the
      Annual General Meeting, with the Chair of the Audit and Nomination and
      Remuneration Committees being available to answer shareholders’ questions.
      Final results of proxy appointments and of voting at General Meetings are
      disclosed on the Documents &amp; Papers and Announcements pages of this
      website. Furthermore, if voting decisions stray from Company expectations,
      the Board will engage with those shareholders concerned to understand and
      try to address their concerns.
    </p>
  </Principle>
)
