import React from 'react'
import Principle from 'components/principle'

export default () => (
  <Principle
    number="4"
    summary="Embed effective risk management, considering both opportunities and threats, throughout the organisation."
  >
    <p>
    Given the nature of the Company’s activities the Board is aware of and alert to the importance of effective risk management, considers risks to the business at Board meetings and formally reviews the principal risks to the business at least annually. The managing agents are also periodically invited to Board meetings to provide an update on the insurance market, outlook, risks and challenges.
    </p>
    <p>
    However, the majority of risks to the Company’s future cash flows arise from the subsidiaries’ participations in the results of Lloyd’s syndicates, as detailed on pages 11 and 44 to 47 (inclusive) of the Company’s 31 December 2022 Report and Accounts. These risks are mostly managed by the syndicates’ managing agents. The Company’s role in managing these risks, together with its subsidiaries and members’ agents, is focussed on selection of the syndicate participations; monitoring syndicate performance; and purchasing appropriate member level reinsurance.
    </p>
    <p>
    The Company monitors the performance of the syndicates it supports on a quarterly basis and, in advance of committing support for an upcoming year, will review syndicate business plans with the managing agents. Assurance will be sought that adequate measures are in place to manage risks, absent of which the Company will withdraw support for the next year of account.
    </p>
    <p>
    The Chief Executive Officer and Finance Director meet regularly to review the Company’s ongoing trading performance, discuss budgets and forecasts and any new or emerging risks associated with ongoing trading. The Board regularly reviews actual performance against budgets and forecasts, as well as anything brought to its attention following the Chief Executive Officer and Finance Director’s meetings. Project milestones and timelines are also reviewed regularly.
    </p>
    <p>
    Opportunities and threats to the Company’s business model and strategy are also periodically considered at dedicated Board strategy meetings.
    </p>
  </Principle>
)