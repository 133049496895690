import React from 'react'
import Principle from 'components/principle'

export default () => (
  <Principle
    number="7"
    summary="Evaluate board performance based on clear and relevant objectives, seeking continuous improvement."
  >
    <p>
    In addition to the Nomination and Remuneration Committee’s review of succession planning, the performance and effectiveness of the Board, its Committees and of individual Directors is reviewed annually. On an on-going basis Directors are encouraged to raise any issues or concerns with the Chairman as soon as appropriate, as the Chairman will do in the event there are any matters causing the Company concern.
    </p>
    <p>
    In addition, the performance of all continuing Directors is considered before they are proposed for re-election at each AGM.
    </p>
    <p>
    During 2022 the Board completed a formal review of its own performance and the performance of the Board’s Committees and the Chairman. The review was conducted internally by the Company Secretary and consisted of written responses to a standard questionnaire. Views and recommendations were consolidated into a report which was presented to the Board for review. Matters requiring further consideration were either referred to the Nomination and Remuneration Committee or were allocated to the Board as a whole for further analysis, and issues raised by the evaluation exercise were used to improve the effectiveness of the Board and introduce improvements to Board processes.  A further evaluation of performance has commenced and will be completed by the end of 2023.
    </p>
  </Principle>
)