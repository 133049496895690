import React from "react"
import Principle from "components/principle"

export default () => (
  <Principle
    number="9"
    summary="Maintain governance structures and processes that are fit for purpose and support good decision- making by the board."
  >
    <p>
      Arrangements made by the Company and structures in place to appropriately
      address corporate governance are set out above and are detailed in the
      Corporate Governance Statement on pages 20-25 of our Report and Accounts
      for the year ended 31 December 2022. These arrangements and all policies
      and practices are periodically reviewed by the Board throughout the year
      to ensure they remain effective and, wherever possible and appropriate,
      are in accordance with corporate governance best practice.
    </p>
    <p>
      The Board expects the Company’s governance structures and processes to
      evolve over time as the Company develop and grows in accordance with
      strategy. Disclosures on this website will therefore be periodically
      updated to reflect these changes.
    </p>
    <p>
      The Board is collectively responsible for formulating, reviewing and
      approving the Company’s strategy; determining the budget; approving
      corporate actions; monitoring performance and progress against plans and
      strategy; and for corporate governance within the Company. In order to do
      this effectively, regular informal discussions are held between the
      Executive and Non-executive Directors. The Board meets formally at least
      four times each calendar year, and at such other times as required.
    </p>
    <p>
      The Company’s Executive Chairman, Michael Wade, is responsible for running
      the Board effectively and ensuring the Company’s approach to corporate
      governance is appropriate, with assistance from the Company Secretary. The
      Executive Chairman has also been instrumental in formalising regular,
      dedicated strategy meetings.
    </p>
    <p>
      The Company Secretary ensures that all Directors receive regular and
      timely information about the Company’s operational and financial
      performance and that all necessary information is circulated to the
      Directors sufficiently in advance of meetings to enable the Board to have
      meaningful discussions and make informed decisions. In addition, all
      Directors have access to advice and assistance from the Company Secretary
      and are permitted to obtain independent professional advice at the
      Company’s expense where they consider it is necessary for them to
      effectively discharge their duties.
    </p>
    <p>
      The Executive Directors are responsible for the day-to-day management of
      the Company, running the business and informing and consulting with the
      Board about any significant financial and operational matters.
    </p>
    <p>
      The Company’s Chief Executive Officer (‘CEO’), is responsible for the
      Company’s operations and implementing strategy and for keeping the Board
      appraised of conditions and developments in the market, including
      competition, opportunities for acquisitions and expansion, industry
      developments, new product ideas, and pending changes to rules and
      regulations that might impact the Company. Arthur Manners, the Finance
      Director, works alongside the CEO and has overall control and
      responsibility for all financial aspects of the Company’s strategy. The
      Finance Director is responsible for the Company’s out-sourced accounting
      function and ensures all financial systems are robust, compliant and
      support current activities and plans for future growth. The Finance
      Director also co-ordinates corporate finance and has responsibility for
      funding and capital requirements, debt, taxation, equity and acquisition
      finance.
    </p>
    <p>
      Other key areas of responsibility for the Non-executive Directors include
      constructively challenging and helping to develop proposals on strategy:
      monitoring and scrutinising reporting of performance against agreed goals
      and objectives; determining the integrity of financial information and
      that financial controls and risk management systems are robust and
      defensible; and determining remuneration of the Executive Directors,
      appointing and removing Executive Directors and planning succession
      (through the Nomination and Remuneration Committee as detailed below).
    </p>
    <p>
      The Board has a formal schedule of matters that require approval by the
      Board and is supported by the Audit and Nomination and Remuneration
      Committees as detailed below.
    </p>
    <p>
      A copy of the Schedule of Matters Reserved to the Board and of the Audit
      Committee and Nomination and Remuneration Committee Terms of Reference are
      available on the Documents &amp; Papers page of this website.
    </p>
    <h3 className="h4 mt-20">Audit Committee</h3>
    <p>
      The Audit Committee meets at least twice a year and is responsible for
      ensuring that the Group’s financial performance is properly monitored,
      controlled and reported. The major tasks undertaken by the Committee
      include monitoring the integrity of the Company’s financial reporting,
      reviewing internal controls and risk management systems and oversight of
      the external audit process. The CEO and Finance Director are invited to
      attend the Audit Committee meetings if appropriate.
    </p>
    <p>
      The Audit Committee oversees the appointment of external auditors, reviews
      their performance and meets with them to discuss audit planning, review
      the Company’s financial statements (including the annual and interim
      results) and audit findings, and to discuss reports from the auditors on
      the effectiveness and adequacy of the Company’s internal controls, risk
      management systems and accounting policies. The members of the Audit
      Committee are all Non-executive Directors, being Tom Libassi and Andrew
      Christie, who is also chairman of the committee.
    </p>
    <h3 className="h4 mt-20">Nomination and Remuneration Committee</h3>
    <p>
      The Nomination and Remuneration Committee meets at least twice a year and
      is responsible for determining and recommending to the Board the terms and
      conditions of employment and remuneration of Executive Directors including
      setting targets and approving payments in respect of performance related
      incentives, bonuses and share scheme awards. No Director is involved in
      determining his own remuneration and the Executive Chairman is excluded
      from discussions when the Board determines the Non-executive Directors’
      remuneration (including his own). The Committee’s terms of reference try
      to ensure that members of the executive management are provided with
      sufficient incentives to encourage enhanced performance and are in a fair
      and responsible manner rewarded for their individual contribution to the
      success of the Company.
    </p>
    <p>
      The Nomination and Remuneration Committee has responsibility for
      periodically reviewing the structure, size and composition of the Board
      with a view to the Company’s strategy and likely future requirements;
      considering succession planning; and identifying candidates and
      recommending new appointments to the Board.
    </p>
    <p>
      The members of the Nomination and Remuneration Committee are all
      Non-executive Directors, being Michael Wade, Tom Libassi and Andrew
      Christie, who is also the committee chairman.
    </p>
  </Principle>
)
