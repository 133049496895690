import React from 'react'
import Principle from 'components/principle'

export default () => (
  <Principle
    number="10"
    summary="Communicate how the company is governed and is performing by maintaining a dialogue with shareholders and other relevant stakeholders.."
  >
    <p>
    As detailed under QCA Code Principle 2 above, the Board is committed to communicating effectively with the Company’s shareholders and other stakeholders, and to understanding their needs and expectations. To achieve this the Board encourages two-way communication with investors and stakeholders and responds appropriately to ensure all questions or issues received from them are addressed in a timely manner.  The Chief Executive Officer, Finance Director and Chairman have regular, direct contact with large shareholders and make sure that their opinions are communicated to the Board as needed.
    </p>
    <p>
    Shareholders and stakeholders are advised about trading performance and any significant developments through timely announcements made to the market electronically via the London Stock Exchange’s Regulatory New Service (‘RNS’). These announcements are also automatically uploaded on the Announcements page of this website and copies of press releases, broker notes and analysts’ presentations are placed on the Press and Presentations pages of the website.
    </p>
    <p>
    In addition, the Board recognises the opportunity the AGM provides for them to meet the Company’s shareholders. At the AGM shareholders can pose questions and raise issues either formally during the meeting or informally immediately following the AGM. The Company uses the Annual General Meeting as an opportunity to communicate with its shareholders. In so far as is practicably possible, all Directors are expected to attend the Annual General Meeting, with the Chair of the Audit and Nomination and Remuneration Committees being available to answer shareholders’ questions. All outcomes of votes and historical annual reports and other governance-related material are included on the Announcements, Corporate Governance and Reports &amp; Accounts pages of this website.
    </p>
  </Principle>
)